<template>
  <div>
    <!-- TItle -->
    <div>
      <h1
        @click="test"
        v-if="!wait"
        class="text-2xl text-center font-semibold text-gray-700"
      >
        Escanea el código QR
      </h1>
      <h1 v-else class="text-2xl text-center font-semibold text-green-700">
        Detectado: {{ code }}
      </h1>
    </div>
    <pre></pre>
    <qrcode-stream
      formats="qr_code"
      @detect="onDetect"
      :track="paintBoundingBox"
      @error="logErrors"
    />
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapGetters } from "vuex";
export default {
  components: { QrcodeStream },
  layout: "empty",
  data() {
    return {
      error: "",
      code: null,
      wait: false,
      timeout: null,
    };
  },
  created() {
    this.$store
      .dispatch("auth/authenticate")
      .then(() => {
        console.log("auth");
      })
      .catch(() => {
        console.log("no auth");
        this.$router.push("/login");
      });
  },
  computed: {
    ...mapGetters("auth", { user: "user" }),
  },
  methods: {
    async test() {
      // console.log(this.user);
      // if (!this.user) {
      //   this.$toast.error("No se encontró el usuario");
      //   return;
      // }
      // this.$store.dispatch("scan-qr/create", {
      //   idUsr: this.user._id,
      //   orderId: "6583a816a5e2e4001155d704",
      // });
    },
    paintBoundingBox(detectedCodes, ctx) {
      if (this.wait) return;
      if (!detectedCodes) return;
      if (detectedCodes.length < 1) return;
      const detectedCode = detectedCodes[0];
      this.code = detectedCode;

      const {
        boundingBox: { x, y, width, height },
      } = detectedCode;

      ctx.lineWidth = 8;
      // Stroke red
      ctx.strokeStyle = "#f00";
      ctx.strokeRect(x, y, width, height);

      const url = detectedCodes[0].rawValue;

      // Obtener la parte despues del ultimo =
      const tmp = url.split("=");
      this.code = tmp[tmp.length - 1];

      this.$store.dispatch("scan-qr/create", {
        idUsr: this.user._id,
        orderId: this.code,
      });
      this.wait = true;

      //Terminate the timeout if it already exists
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        this.wait = false;
      }, 5000);
    },

    logErrors(error) {
      if (error) {
        console.error(error);
        this.error = error;
      }
    },
  },
};
</script>
